<template>
    <div class="contact-button" @click="goToRoute">
        <div class="icon-circle">
            <v-icon color="white">mdi-message-question</v-icon>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goToRoute() {
            this.$router.push({ name: 'Contact' });
        }
    }
}
</script>

<style scoped>
.icon-circle {
    background-color: black;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000;
    cursor: pointer;
}
</style>