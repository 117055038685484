import { render, staticRenderFns } from "./ButtonContact.vue?vue&type=template&id=890dbce0&scoped=true"
import script from "./ButtonContact.vue?vue&type=script&lang=js"
export * from "./ButtonContact.vue?vue&type=script&lang=js"
import style0 from "./ButtonContact.vue?vue&type=style&index=0&id=890dbce0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "890dbce0",
  null
  
)

export default component.exports